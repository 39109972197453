@charset "utf-8";

@import "_root.scss";

// 국문      notosans
// 영문/숫자 Outfit

/**
*	국문 U+AC00-D7A3 
	디폴트 선언
	https://fonts.googleapis.com/earlyaccess/notosanskr.css

* 영문 대문자 U+0041-005A, 소문자 U+0061-007A
  숫자 U+0030-0039
	https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800&display=swap
*/
@font-face {
	font-family: "NotoSansKR";
	font-weight: 100;
	font-display: swap;
	src:
		local("NotoSansKR-Thin"),
		url("../font/NotoSansKR-Thin.ttf") format("truetype");
	unicode-range: U+AC00-D7A3;
}

@font-face {
	font-family: "NotoSansKR";
	font-weight: 300;
	font-display: swap;
	src:
		local("NotoSansKR-Light"),
		url("../font/NotoSansKR-Light.ttf") format("truetype");
	unicode-range: U+AC00-D7A3;
}

@font-face {
	font-family: "NotoSansKR";
	font-weight: 400;
	font-display: swap;
	src:
		local("NotoSansKR-Regular"),
		url("../font/NotoSansKR-Regular.ttf") format("truetype");
	unicode-range: U+AC00-D7A3;
}

@font-face {
	font-family: "NotoSansKR";
	font-weight: 500;
	font-display: swap;
	src:
		local("NotoSansKR-Medium"),
		url("../font/NotoSansKR-Medium.ttf") format("truetype");
	unicode-range: U+AC00-D7A3;
}

@font-face {
	font-family: "NotoSansKR";
	font-weight: 700;
	font-display: swap;
	src:
		local("NotoSansKR-Bold"),
		url("../font/NotoSansKR-Bold.ttf") format("truetype");
	unicode-range: U+AC00-D7A3;
}

@font-face {
	font-family: "NotoSansKR";
	font-weight: 900;
	font-display: swap;
	src:
		local("NotoSansKR-Black"),
		url("../font/NotoSansKR-Black.ttf") format("truetype");
	unicode-range: U+AC00-D7A3;
}

@font-face {
	font-family: "Outfit";
	font-weight: 100;
	font-display: swap;
	src:
		local("Outfit-Thin"),
		url("../font/Outfit-Thin.ttf") format("truetype");
	unicode-range: U+0030-0039, U+0061-007A, U+0041-005A;
}

@font-face {
	font-family: "Outfit";
	font-weight: 300;
	font-display: swap;
	src:
		local("Outfit-Light"),
		url("../font/Outfit-Light.ttf") format("truetype");
	unicode-range: U+0030-0039, U+0061-007A, U+0041-005A;
}

@font-face {
	font-family: "Outfit";
	font-weight: 400;
	font-display: swap;
	src:
		local("Outfit-Regular"),
		url("../font/Outfit-Regular.ttf") format("truetype");
	unicode-range: U+0030-0039, U+0061-007A, U+0041-005A;
}

@font-face {
	font-family: "Outfit";
	font-weight: 500;
	font-display: swap;
	src:
		local("Outfit-Medium"),
		url("../font/Outfit-Medium.ttf") format("truetype");
	unicode-range: U+0030-0039, U+0061-007A, U+0041-005A;
}

@font-face {
	font-family: "Outfit";
	font-weight: 600;
	font-display: swap;
	src:
		local("Outfit-SemiBold"),
		url("../font/Outfit-SemiBold.ttf") format("truetype");
	unicode-range: U+0030-0039, U+0061-007A, U+0041-005A;
}

@font-face {
	font-family: "Outfit";
	font-weight: 700;
	font-display: swap;
	src:
		local("Outfit-Bold"),
		url("../font/Outfit-Bold.ttf") format("truetype");
	unicode-range: U+0030-0039, U+0061-007A, U+0041-005A;
}

@font-face {
	font-family: "Outfit";
	font-weight: 800;
	font-display: swap;
	src:
		local("Outfit-ExtraBold"),
		url("../font/Outfit-ExtraBold.ttf") format("truetype");
	unicode-range: U+0030-0039, U+0061-007A, U+0041-005A;
}

@font-face {
	font-family: "Outfit";
	font-weight: 900;
	font-display: swap;
	src:
		local("Outfit-Black"),
		url("../font/Outfit-Black.ttf") format("truetype");
	unicode-range: U+0030-0039, U+0061-007A, U+0041-005A;
}

// 국문 예외 폰트
@font-face {
	font-family: "Pretendard";
	font-weight: 300;
	font-display: swap;
	src:
		local("Pretendard-Regular"),
		url("../font/Pretendard-Regular.woff2") format("woff2"),
		url("../font/Pretendard-Regular.woff") format("woff");
}

/* basic-reset */
*,
*:before,
*:after {
	box-sizing: border-box;
}

html {
	-webkit-text-size-adjust: 100%;
}

body {
	font-family:
		"Pretendard",
		"Roboto",
		Arial,
		Dotum,
		serif,
		Pretendard,
		-apple-system,
		BlinkMacSystemFont,
		system-ui,
		Roboto,
		"Helvetica Neue",
		"Segoe UI",
		"Apple SD Gothic Neo",
		"Noto Sans KR",
		"Malgun Gothic",
		"Apple Color Emoji",
		"Segoe UI Emoji",
		"Segoe UI Symbol",
		sans-serif;
	-webkit-text-size-adjust: none;
	font-weight: 500;
	line-height: 1.5;
	font-size: 14px;
	// color:;
	/*-webkit-touch-callout:none;-webkit-user-select:none;-webkit-tap-highlight-color:rgba(0, 0, 0, 0);*/
	word-break: break-word;
	word-wrap: break-word;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
textarea,
p,
blockquote,
th,
td,
input,
select,
textarea,
button,
figure,
figcaption {
	margin: 0;
	padding: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

summary {
	display: list-item;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: inherit;
}

fieldset,
img {
	border: 0 none;
	vertical-align: middle;
}

dl,
ul,
ol,
menu,
li {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}

em {
	font-style: normal;
}

b,
strong {
	font-weight: 700;
}

img {
	border: 0;
	-ms-interpolation-mode: bicubic;
	vertical-align: middle;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: "";
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

textarea {
	overflow: auto;
	appearance: none;
	resize: vertical;
	font-size: 100%;
}

input,
select {
	appearance: none;
	font-family: inherit;
	border-radius: 0;
	font-size: 100%;
	outline: none;
}

button {
	font-family: inherit;
	font-size: 100%;
	border: 0;
	background-color: transparent;
	cursor: pointer;
	color: inherit;
	font-weight: inherit;
}

button[disabled],
input[disabled] {
	cursor: default;
}

caption {
	display: none;
}

address,
caption,
cite,
code,
dfn,
em,
var {
	font-style: normal;
}

a {
	color: inherit;
	text-decoration: none;
}

input[type="text"],
input[type="password"],
input[type="submit"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="email"] {
	appearance: none;
}

input:checked[type="checkbox"] {
	background-color: #666;
	appearance: checkbox;
}

input:checked[type="checkbox"] {
	appearance: checkbox;
}

input[type="search"] {
	appearance: textfield;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
	appearance: none;
}

button,
input {
	overflow: visible;
}

button,
[type="button"],
[type="submit"],
[type="reset"],
[type="file"] {
	appearance: button;
	border-radius: 0;
	cursor: pointer;
}

button {
	font-family: inherit;
	font-size: 100%;
}

input,
select,
textarea,
button,
pre {
	font-family: "Pretendard", "Roboto", Arial, Dotum, serif, Pretendard;
}

input:focus,
textarea:focus {
	outline: none;
}

// 공통 input focus border none
input {
	outline: none;
}

// 이미지 url 변수
$path: "../images/";

// 준비중
$mainColor: #1abb9c;
$black: #222222;
$white: #ffffff;
$darkGray: rgba(50, 50, 50, 1);

body {
	font-family: "Helvetica Neue", "Helvetica", Arial, "Lucida   Grande", sans-serif;
}

h3 {
	font-size: 2em;
	font-weight: 100;
	letter-spacing: 1em;
	text-shadow: 0px 0px 20px $mainColor;
	color: $mainColor;
	display: block;
	text-align: center;
	position: relative;
	left:16px;
}

.container {
	font-size: 16px;
	color: $white;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
	text-align: center;
	flex-direction: column;
}

.bar {
	height: 2px;
	width:90%;
	max-width: 34.7em;
	border-radius: 10px;
	background: transparent;
	box-shadow: inset 0px 0px 8px $darkGray;
	overflow: hidden;
	padding: 5px;
}

.progress {
	height: inherit;
	border-radius: inherit;
	width: 0;
	background: $mainColor;
	animation: load 3s linear infinite;
	animation-delay: 2s;
	position: relative;
	top:-1px;
}

@keyframes load {
	0% {
		width: 0%;
	}
	50% {
		width: 100%;
	}
	100% {
		width: 0%;
		float: right;
	}
}

// 공통 mixin include
@include imgHolder;

/* 1024 이상 */
@media all and (min-width: 1024px) {
}
/* //1024 이상 */
