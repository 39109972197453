@charset "utf-8";

// 전역 css color 변수
:root {
	// background

	// border

	// font color

	// 간격 변수

	// z-index
}

// text 1줄
@mixin text-line-1 {
	text-overflow: ellipsis;
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
}

// text 2줄
@mixin text-line-2 {
	text-overflow: ellipsis;
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
}

// 공통 selectbox custom
@mixin selectBoxCustom {
	border-radius: 8px;
	border: 1px solid var(--bordersbasic);
	background: var(--primary-2);
	display: block;
	width: 100%;
	height: 58px;
	line-height: 58px;
	-webkit-appearance: none; // arrow delect
	color: var(--textstitle);
	font-size: 16px;
	font-weight: 500;
	letter-spacing: -0.64px;
	text-indent: 15px;
	background-repeat: no-repeat;
	background-position: right 15px center;
	position: relative;
	box-sizing: border-box;
	margin-bottom: 8px;
	label {
		position: absolute;
		top: 18px;
		left: 0;
		color: #8c8c8c;
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
		letter-spacing: -0.64px;
	}
	button {
		position: absolute;
		top: 3px;
		left: 0;
		right: 0;
		bottom: 0;
		padding: 0 34px 0 0;
		color: var(--textstitle);
		text-align: right;
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
	}
}

// 공통 이미지 holder
@mixin imgHolder {
	.img-holder {
		a {
			padding-top: 100%;
			position: relative;
			overflow: hidden;
			display: block;
			img {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				object-fit: cover;
				width: 100%;
				height: 100%;
				border: 1px solid #ccc;
				border-radius: 10px;
			}
		}
	}
}
