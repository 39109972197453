/* user custom */
.top-nav {
  display: none !important;
}
#fp-nav ul li,
.fp-slidesNav ul li {
  width: 20px !important;
  height: 20px !important;
}
#fp-nav ul li a span,
.fp-slidesNav ul li a span {
  transform: translate(-50%, -50%) !important;
}
#fp-nav ul li a span,
#fp-nav ul li:hover a span,
.fp-slidesNav ul li a span,
.fp-slidesNav ul li:hover a span,
#fp-nav ul li a.active span,
#fp-nav ul li:hover a.active span,
.fp-slidesNav ul li a.active span,
.fp-slidesNav ul li:hover a.active span {
  width: 20px !important;
  height: 20px !important;
  margin: 0 !important;
}
#fp-nav ul li a span, .fp-slidesNav ul li a span{
  background-color: #ccc !important;
  opacity: 0.5 !important;
  border:2px solid #ccc !important;
}
#fp-nav ul li a span,
#fp-nav ul li:hover a span,
.fp-slidesNav ul li a span,
.fp-slidesNav ul li:hover a span {
  width: 10px !important;
  height: 10px !important;
}

#fp-nav {
  margin-top: 0 !important;
  transform: translate(0, -50%) !important;
}

#section1 {
  color: #fff !important;
}

.fp-section {
  text-align: center;
}

.fp-slide {
  height: 50vh !important;
}

button {
  padding: 0.93em 1.87em;
  background: #35495e;
  border-radius: 5px;
  border-color: transparent;
  display: block;
  color: #fff;
  margin: 0 auto;
  cursor: pointer;
  font-size: 0.85em;
}

.section {
  height: 100vh !important;
}

.section h3 {
  font-size: 5em;
  text-align: center;
  color: #fff;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh !important;
}

.fp-warning,
.fp-watermark a {
  display: none !important;
}
.fp-controlArrow.fp-prev{left:50px;}
.fp-controlArrow.fp-next{right:50px;}

.section1{
  background-image: url('./assets/images/main_bg_01.jpg');
  background-repeat: no-repeat;
  background-position: 100% bottom;
  background-size:70%;
  /* animation: load2 5s infinite, load3 5s forwards; */
  animation: load2 5s infinite;
}

@keyframes load2 {
	0% {
		background-image: url('./assets/images/main_bg_01.jpg');
    background-position: 100% bottom;
	}
	50% {
		background-image: url('./assets/images/main_bg_02.jpg');
    background-position: 90% bottom;
	}
	100% {
		background-image: url('./assets/images/main_bg_01.jpg');
    background-position: 100% bottom;
	}
}

@keyframes load3 {
	0% {
		transform: scale(0.3);
	}
	100% {
		transform: scale(1);
	}
}

.section2 .fp-overflow{display: flex;
  justify-content: center;
  align-items: center;height: 100vh;}
.section2 .ceo-img{
  background-image: url('./assets/images/CEO.png');
  background-repeat: no-repeat;
  background-color: #222;
  background-size: 100%;
  background-position: center center;
  border-radius: 99999px;
  width: 30vh;
  height: 30vh;
}
.section2{transform:scale(0);}
.section2.show{
  animation: ceo 3s forwards;
}
@keyframes ceo {
	0% {
    transform: scale(0);
	}
	100% {
		transform: scale(1);
	}
}